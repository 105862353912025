var windowWidth, windowHeight, documentWidth, documentHeight, topScrollPosition;
var siteRoot = window.location.protocol + "//" + window.location.host;

jQuery(document).ready(function($){

    function recalculateSizes() {
        windowWidth = $(window).width(); //retrieve current window width
        windowHeight = $(window).height(); //retrieve current window height
        documentWidth = $(document).width(); //retrieve current document width
        documentHeight = $(document).height(); //retrieve current document height
        topScrollPosition = $(window).scrollTop(); //retrieve the document scroll ToP position
    }
    recalculateSizes();

    $('form.nk-register-form').on('submit',function(e){
        e.preventDefault();
        let thisForm = $(this);
        // console.log(thisForm);
        let checkboxes = 'ok';
        if($('form.nk-register-form .nk-check:checked').length < 2) {
            checkboxes = 'not-ok';
        }

        thisForm.find('.sub-modal').html('<p class="processing">processing ...</p>');
        let user = {
            email : thisForm.find('.nk-email').val(),
            name : thisForm.find('.nk-name').val(),
            slug : thisForm.find('.nk-slug').val(),
            redirection : thisForm.find('.nk-redirection').val(),
            checkboxes : checkboxes,
        }

        $.ajax({
            type:'POST',
            dataType:'json',
            url:nk_ajax_user_object.ajaxurl,
            data:{
                'action':'nk_ajax_user_register',
                'user': user,
                'security': nk_ajax_user_object.nkNonce,
            },
            success:function(data){
                // console.log('returned user :', data);
                if(data.errors == 0){
                    // redirection here
                    $(location).attr('href', data.redirect_success_url);
                } else {
                    // console.log(data.errors);
                    thisForm.find('.sub-modal').html(data.errors);
                    // alert(data.errors);
                }
                // thisForm.find('.sub-modal').text('data-complete');
            },
            error:function(xhr, ajaxOptions, thrownError) {
                // fire something like we had a problem with your request, please try again later
                alert(xhr.status);
                alert(thrownError);
            },
            always:function() {
                // thisForm.find('.sub-modal').text('data-complete');
            }
        });

    });

    // console.log('aweber_tags_array', aweber_tags_array);
    aweber_tags_array.forEach(element => {
        $('.' + element.tag_name)
            .attr('attr-aweber', element.tag_name)
            .attr('attr-redirection', element.redirection)
            .attr('attr-title', element.popup_title)
            .attr('attr-button', element.popup_button);
        // console.log(element);
    });

    function popupOpen() {
        $('body').addClass('nk-popup-opened');
    }

    function popupClose() {
        $('body').removeClass('nk-popup-opened');
    }

    $('body').on('click', '[attr-aweber]', function(e) {
        e.preventDefault();
        let redirectionId = $(this).attr('attr-redirection');
        const popupTitle = $(this).attr('attr-title');
        const popupButtonText = $(this).attr('attr-button');
        let popup = $('.nk-register-popup');
        // fb: https://frt.lcg.design/?wc-api=auth&start=facebook&return=https%3A%2F%2Fmystore.com%2Fmy-account
        let socialFacebookUrlRedirection = siteRoot + '/?wc-api=auth&start=facebook&return=' + encodeURIComponent(aweber_url_array[redirectionId]);
        popup.find('.popup-title').html(popupTitle);
        popup.find('.nk-submit').text(popupButtonText);
        popup.find('.logged-in-button').text(popupButtonText);
        popup.find('input.nk-redirection').val(redirectionId);
        popup.find('.button-social-login-facebook').attr('href', socialFacebookUrlRedirection);
        popup.find('.logged-in-button').attr('href', aweber_url_array[redirectionId]);
        // console.log(aweber_url_array[redirectionId]);

        //
        // do all the animations with popup
        //
        popupOpen();

    });
    $('body').on('click', '.nk-popup-close', function() {
        popupClose();
    });

    // popup actions
    $('.nk-register-popup .nk-check').on('change', function() {
        if($('.nk-register-popup .nk-check:checked').length == 2) {
            $('.nk-register-popup').removeClass('not-fully-active');
        } else {
            $('.nk-register-popup').addClass('not-fully-active');
        }
        $(this).closest('.nk-label').removeClass('not-checked');
    });


    /*--------------------------------------------------------------------------
    *
    *   Competition
    *
    *-------------------------------------------------------------------------*/
    function prepareSelections() {
        var selectionDiv = $('.tipster-selection');
        if(!selectionDiv.length) return;

        // console.log(cards);
        // console.log(cardsDate);

        function prepareLocation() {
            let locations = ['Racecourse', ...Object.keys(cards)];
            let options = locations.map(location => `<option>${location}</option>`);

            $(selectionDiv).find('.racecourse').append(options).fadeIn();

        }

        function prepareTime(location) {
            let times = ['Time', ...Object.keys(cards[location])];
            let options = times.map(time => `<option>${time}</option>`);
            return options;
        }

        function prepareHorses(location, time) {
            let horses = cards[location][time];
            horses.sort((a, b) => a.Horse.localeCompare(b.Horse));
            let options = horses.map(horse => `<option attr-horse-nr="${horse.No}" attr-horse-name="${horse.Horse}">${horse.Horse} (${horse.No})</option>`);
            return options;
        }

        $('.racecourse').on('change', function() {
            $('.action-info').slideUp();
            const location = $(this).find('option:selected').text();
            if(location == 'Racecourse') {
                $(this).closest('.tipster-selection').find('.race-time').hide().html('');
                $(this).closest('.tipster-selection').find('.horse').hide().html('');
                return;
            }
            const times = prepareTime(location);
            $(this).closest('.tipster-selection').find('.race-time').html(times).fadeIn();
            $(this).closest('.tipster-selection').find('.horse').html('').fadeOut();
        });

        $('.race-time').on('change', function() {
            $('.action-info').slideUp();
            const location = $(this).closest('.tipster-selection').find('.racecourse option:selected').text();
            const time = $(this).find('option:selected').text();
            if(time == 'Time') {
                $(this).closest('.tipster-selection').find('.horse').hide().html('');
            }
            const defaultHorseSelection = `<option>Horse</option>`;
            console.log(location, time);
            const horses = prepareHorses(location, time);
            $(this).closest('.tipster-selection').find('.horse').html('').append(defaultHorseSelection).append(horses).fadeIn();
        });

        prepareLocation();

        // submit and collectform data
        function collectData() {
            console.log('collect');
            var tip = {
                votes : [],
                fbName : $('.user-fb').val(),
                raceDate: cardsDate
            };
            let validation = true;
            $('.tipster-selection').each(function( index ) {
                const race = $(this).find('.racecourse option:selected').text();
                const time = $(this).find('.race-time option:selected').text();
                const horseNr = $(this).find('.horse option:selected').attr('attr-horse-nr');
                const horseName = $(this).find('.horse option:selected').attr('attr-horse-name');

                // validate data
                if (race == 'Racecourse' || time == 'Time' || typeof horseNr === 'undefined' || tip.fbName === '') {
                    console.log('validation');
                    validation = false;
                }
                const vote = {};
                vote.race = race;
                vote.time = time;
                vote.horseNr = horseNr;
                vote.horseName = horseName;
                tip.votes.push(vote);
            });
            if (validation == true) {
                return tip;
            } else {
                console.log('validation false');
                $('.action-info').html('There are some errors in your selections ...').slideDown();
                return false;
            }
        }

        $('body').on('click', '.submit-competition', function(e) {
            $('.action-info').slideUp();
            e.preventDefault();
            $(this).prop('disabled', true);
            let tips = collectData();

            console.log(tips);

            if(tips != false) { // if we collected and validated data, send them to database
                $('.action-info').text('We are saving your tips ...').slideDown();
                $.ajax({
                    type:'POST',
                    dataType:'json',
                    url:nk_ajax_user_object.ajaxurl,
                    data:{
                        'action':'nk_ajax_save_vote',
                        'tips': tips,
                        'security': nk_ajax_user_object.nkNonce,
                    },
                    success:function(data){
                        console.log('returned info :', data);
                        if(data.errors == 0){
                            $('.action-info').text('Thank you for your selections');
                            window.location.replace(siteRoot + '/todays-entries/');
                            // redirection here
                            // $(location).attr('href', data.redirect_success_url);
                        } else {
                            console.log(data.errors);
                            $(this).prop('disabled', true);
                            // thisForm.find('.sub-modal').html(data.errors);
                            // alert(data.errors);
                        }
                        // thisForm.find('.sub-modal').text('data-complete');
                    },
                    error:function(xhr, ajaxOptions, thrownError) {
                        // fire something like we had a problem with your request, please try again later
                        alert(xhr.status);
                        alert(thrownError);
                    },
                    always:function() {
                        // thisForm.find('.sub-modal').text('data-complete');
                        $(this).prop('disabled', false);
                    }
                });
            } else {
                $(this).prop('disabled', false);
            }

        });


    }
    prepareSelections();

    // execute on resize but only once
    var doResize;
    $(window).on('resize', function() {

        clearTimeout(doResize);
        doResize = setTimeout(function() {

        }, 300);
    });

    var doScroll;
    $(window).on('scroll', function() {

        clearTimeout(doScroll);
        doScroll = setTimeout(function() {

        }, 300);
    });

    $( window ).on( "orientationchange", function( event ) {

    });

});


jQuery(window).on('load', function() {
    // deferImages();
});